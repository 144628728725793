<template>
  <el-container class="loginBg">
    <el-main>
      <el-row type="flex" justify="center">
        <el-col :span="6" :xs="24">
          <p class="sysName">{{ GLOBAL.sysName }}</p>
          <el-form
            class="loginCard"
            ref="form"
            :model="form"
            label-width="60px"
            :rules="rules"
            @keyup.enter.native="onSubmit"
          >
            <el-form-item label="账号" prop="account">
              <el-input v-model="form.account" autocomplete="off"></el-input>
            </el-form-item>
            <el-form-item label="密码" prop="password">
              <el-input
                v-model="form.password"
                autocomplete="off"
                type="password"

              ></el-input>
            </el-form-item>
            <p style="text-align: center">
              <el-button type="primary" @click="onSubmit">登录</el-button>
            </p>
          </el-form>
        </el-col>
      </el-row>
    </el-main>
  </el-container>
</template>

<script>
export default {
  metaInfo () {
    return {
      title: this.GLOBAL.sysName
    }
  },
  components: {},
  beforeCreate(){
    sessionStorage.clear();
  },
  mounted(){
    if(location.href.indexOf("#reloaded") == -1) {
      location.href = location.href + "#reloaded";
      location.reload();
    }
  },
  data() {
    return {
      // logo: require("../assets/logo.png"),
      form: {
        email: "",
        password: "",
      },
      rules: {
        email: [{ required: true, message: "请输入账号", trigger: "blur" }],
        password: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  methods: {
    onSubmit() {
      let self = this;
      self.$refs["form"].validate((valid) => {
        if (valid) {
          self.$axios
            .post("/admin/login", JSON.stringify(self.form), {
              headers: { "content-type": "application/json" },
            })
            .then(function (response) {
              sessionStorage.setItem(
                "userInfo",
                JSON.stringify(response.data.data)
              );
              self.$message({
                message: "登录成功",
                type: "success",
              });
              if(response.data.data.role == 1){
                self.$router.replace("/project/list");
              } else if (response.data.data.role == 2) {
                self.$router.replace("/report/projectList");
              }else{
                self.$router.replace("/patrol_survey/list");
              }
            })
            .catch(function (error) {
              self.$message({
                message: error.response.data.message,
                type: "warning",
              });
            });
        } else {
          self.$message({
            message: "请输入正确的账号密码",
            type: "warning",
          });
          return false;
        }
      });
    },
  },
};
</script>
<style>
.sysName {
  text-align: center;
  font-size: 24px;
  margin-bottom: 40px;
}
.loginBg {
  background-image: url("../assets/login-bg.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.loginCard {
  background-color: #fff;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 12px 20px rgba(38, 38, 38, 0.12);
  border-radius: 12px;
  padding: 60px 36px 10px 36px;
}
</style>
